import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: theme.white,
    padding: [[`${theme.doubleGap}rem`, 0]],
    flexWrap: 'var(--pages-store-locations-item-flex-wrap)',
  },
  hero: {
    flexGrow: 1,
    flexShrink: 1,
    order: 'var(--pages-store-locations-item-hero-order)',
    flexBasis: 'var(--pages-store-locations-item-hero-flex-basis)',
  },
  content: {
    order: 2,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'var(--pages-store-locations-item-content-flex-basis)',
    '& p': {
      lineHeight: 1.43,
      fontSize: '1.4rem',
      color: theme.colors.text,
      margin: 0,
    },
  },
  map: {
    order: 4,
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      flexBasis: '36%',
      flexGrow: 1,
      flexShrink: 1,
    },
  },
  imageBannerBox: {
    aspectRatio: 'var(--pages-store-locations-item-image-ratio)',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
      objectPosition: 'center top',
      width: '100%',
      height: '100%',
    },
  },
  link: {
    color: 'currentColor',
    textDecoration: 'none',
  },
  row: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '1.5rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      flexDirection: 'row',
      gap: '3rem',
    },
  },
  section: {
  },
  title: {
    textTransform: 'uppercase',
    marginTop: '3rem',
  },
  infoBox: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  mapStyle: {
    width: '100%',
    height: 'auto',
    display: 'grid',
    aspectRatio: 'var(--pages-store-locations-item-map-ratio)',
  },
  carouselLocation: {
    img: {
      width: 'inherit',
    },
  },
}))

export default useStyles
